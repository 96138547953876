import React, { useCallback, useEffect } from "react";
import {
  Hamburger,
  NavigationItem,
  useGlobalState,
  SocialLinks,
} from "components";
import { decodeEntities } from "../../utils/helpers";
import { useIsClient } from "hooks";
import "./Navigation.scss";

export const Navigation = ({ menuData, footerInfo: { locations } }) => {
  const {
    dispatch,
    state: { menuOpen },
  } = useGlobalState();
  const isClient = useIsClient();

  // closes the mobile menu if esc key is pressed
  const handleEscKey = useCallback(
    (event) =>
      event.keyCode === 27 &&
      menuOpen &&
      dispatch({ type: "setMenuOpen", data: false }),
    [menuOpen, dispatch]
  );

  useEffect(() => {
    if (isClient) {
      document.addEventListener("keydown", handleEscKey, false);
      return () => {
        document.removeEventListener("keydown", handleEscKey);
      };
    }
  }, [handleEscKey, isClient]);

  return (
    <>
      <Hamburger />
      {/* Sort through our menu */}
      <div className={`navigation${menuOpen ? " isOpen" : ""}`}>
        {/* Fake line for when the menu is open */}
        <div className="divider"></div>
        <div className="layout">
          <div className="navigation-container">
            <ul className="navigation-list">
              {menuData?.items
                ?.map((node, index) =>
                  node && !node?.parentId && node?.url ? (
                    <NavigationItem
                      key={`${node.url}-${index}`}
                      menuItem={node}
                    />
                  ) : null
                )
                .filter((node) => !!node)}
            </ul>

            <footer className="navigation-footer">
              <span className="navigation-footer__title">Showroom</span>
              {locations.map((location, index) => (
                <React.Fragment key={index}>
                  <address className="navigation-footer__content">
                    {location?.addressStreet}
                    <br />
                    {location?.addressCity} {location?.addressRegion}{" "}
                    {location?.addressPostCode}
                  </address>
                  <span className="navigation-footer__title">Contact Us</span>
                  <a
                    className="navigation-footer__content"
                    href={`tel:${location?.phone}`}
                  >
                    {location?.phone}
                  </a>
                  <a
                    className="navigation-footer__content"
                    href={`mailto:${decodeEntities(location?.email)}`}
                  >
                    {decodeEntities(location?.email)}
                  </a>
                </React.Fragment>
              ))}

              <span className="navigation-footer__title">Social</span>
              <SocialLinks />
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};
