import React from "react";
import { ReactComponent as Facebook } from "./facebook.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as LinkedIn } from "./linkedin.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as YouTube } from "./youtube.svg";

export const icons = Object.freeze({
  facebook: <Facebook />,
  instagram: <Instagram />,
  linkedin: <LinkedIn />,
  twitter: <Twitter />,
  youtube: <YouTube />,
});

export default icons;
