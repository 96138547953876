import React from "react";
import { ImagePass } from "../../Image/ImagePass";
import { RenderShortcode } from "../../RenderShortcode";
import "./ImageAndContentBlock.scss";

export const ImageAndContentBlock = ({ content, image, backgroundColour, marginPadding }) => {
  return (
    <section className={`image-content layout ${backgroundColour} ${marginPadding}`}>
      <div className="image-content-container">
        <div className="col">
          <div className="image-container">
            <ImagePass src={image} />
          </div>
        </div>
        <div className="col content">
          <RenderShortcode content={content} />
        </div>
      </div>
    </section>
  );
};

ImageAndContentBlock.defaultProps = {
  backgroundColour: "white",
};
