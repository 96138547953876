import React from "react";
import { GatsbyLink } from "components";
import "./Button.scss";

export const Button = ({ theme = "dark", to, children }) => {
  return (
    <GatsbyLink to={to} className={`button ${theme}`}>
      {children}
    </GatsbyLink>
  );
};
