import React, { useState, useEffect } from "react";
import { ReactComponent as ChevronLeft } from "images/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "images/icons/chevron-right.svg";
import { pad_with_zeroes } from "../../utils/helpers";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";

import "../Carousel/Carousel.scss";
import "./CarouselThumbnail.scss";

export const CarouselThumbnail = ({
  children,
  options,
  thumbnailOptions,
  id,
}) => {
  if (!children) return null;

  const [currentSlide, setCurrentSlide] = useState(1);
  const [slideCount, setSlideCount] = useState(null);

  let carousel = new Glide(`.glide-${id}`, options);
  let carouselThumbnail = new Glide(`.glide-thumbnail-${id}`, thumbnailOptions);

  const handleThumbnailClick = (slide_index) => {
    carousel.update({
      startAt: slide_index,
    });
  };

  useEffect(() => {
    carousel.on("run.after", () => {
      setCurrentSlide(carousel.index + 1);
    });

    carousel.mount();
    carouselThumbnail.mount();

    setSlideCount(children.length);
    return () => {
      carousel.destroy();
      carouselThumbnail.destroy();
    };
  }, [carousel, carouselThumbnail]);

  return (
    <div className="carousel-thumbnail">
      <div className={`glide glide-thumbnail-main glide-${id}`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {Object.keys(children).length > 0 &&
              children?.map((child, slide_index) => {
                return (
                  <li className="glide__slide" key={slide_index}>
                    {child}
                  </li>
                );
              })}
          </ul>
        </div>
        {/* Close slides */}

        <span className="current-indicator">
          {pad_with_zeroes(currentSlide, 2)} / {pad_with_zeroes(slideCount, 2)}
        </span>
        {/* Close current position indicator */}

        <div className="glide__bullets" data-glide-el="controls[nav]">
          {Object.keys(children).length > 0 &&
            children?.map((bullet, index) => {
              return (
                <button
                  className="glide__bullet"
                  data-glide-dir={`=${index}`}
                  key={index}
                ></button>
              );
            })}
        </div>
        {/* Close bullets */}
      </div>

      {/* Thumbnail slider  */}
      <div className={`glide glide-thumbnail glide-thumbnail-${id}`}>
        <div className="glide__arrows" data-glide-el="controls">
          <button
            className="glide__arrow glide__arrow--left"
            data-glide-dir="<"
          >
            <ChevronLeft />
          </button>
        </div>
        <div className="glide__arrows" data-glide-el="controls">
          <button
            className="glide__arrow glide__arrow--right"
            data-glide-dir=">"
          >
            <ChevronRight />
          </button>
        </div>
        {/* Close arrows */}

        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {Object.keys(children).length > 0 &&
              children?.map((child, slide_index) => {
                return (
                  <li
                    className="glide__slide"
                    key={slide_index}
                    onClick={() => handleThumbnailClick(slide_index)}
                  >
                    {child}
                  </li>
                );
              })}
          </ul>
        </div>
        {/* Close slides */}

        <div className="glide__bullets" data-glide-el="controls[nav]">
          {Object.keys(children).length > 0 &&
            children?.map((bullet, index) => {
              return (
                <button
                  className="glide__bullet"
                  data-glide-dir={`=${index}`}
                  key={index}
                ></button>
              );
            })}
        </div>
        {/* Close bullets */}
      </div>
    </div>
  );
};

CarouselThumbnail.defaultProps = {
  id: "1001",
  theme: "white",
  options: {
    perView: 3,
    type: "carousel",
  },
  fullWidth: false,
};
