import React, { useState, useEffect } from "react";
import { Header, Footer } from "../components";
import "../sass/global/styles.scss";

const Layout = ({ children, location, pageContext }) => {
  const [isHomepage, setHomepage] = useState(false);

  useEffect(() => {
    setHomepage(false);
    if (pageContext?.page_background === "navy") {
      setHomepage(true);
    }
    if (location?.pathname === `/`) {
      setHomepage(true);
    }
  }, [location]);

  return (
    <div id="layout" className={isHomepage ? "dark" : null}>
      <Header location={location} />
      <div className="main">{children}</div>
      <Footer location={location} />
    </div>
  );
};

export default Layout;
