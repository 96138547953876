import { GatsbyLink } from "components";
import React from "react";
import { RenderShortcode } from "../RenderShortcode";
import "./Navigation.scss";

/** Individual menu item */
export const NavigationSubItem = ({ subMenuItems, closeMenu }) => {
  return (
    <ul className="sub-navigation-list">
      {subMenuItems?.map((item, index) => (
        <li className="sub-navigation-item" key={index}>
          <GatsbyLink
            to={item.url || undefined}
            target={item.target}
            aria-label={item.title}
            title={item.title}
            onClick={closeMenu}
          >
            <RenderShortcode content={item?.title} />
          </GatsbyLink>
        </li>
      ))}
    </ul>
  );
};
