import React from "react";
import { AnimatedLogo } from "./Logo";
import { ReactComponent as Phone } from "images/icons/phone.svg";
import {
  GatsbyLink,
  GlobalStateProvider,
  useGlobalState,
  Navigation,
} from "components";
import "./Header.scss";

import { StaticQuery, graphql, Link } from "gatsby";

const HeaderComponent = ({
  data: {
    primaryNavigation,
    siteSettings: { options },
  },
}) => {
  const {
    dispatch,
    state: { menuOpen },
  } = useGlobalState();

  // handles closing the menu if logo is clicked and page is navigated
  const handleItemClick = () => {
    menuOpen && dispatch({ type: "setMenuOpen", data: false });
  };

  return (
    <header className={`header layout${menuOpen ? " isOpen" : ""}`}>
      <div className="header-container">
        <AnimatedLogo />
        <Link className="contact-button" to="/contact/">
          Contact Us
        </Link>
        <a href={`tel:${options?.locations[0]?.phone}`} className="call-link">
          <span className="call-number">{options?.locations[0]?.phone}</span>
          <Phone />
        </a>
        <Navigation menuData={primaryNavigation} footerInfo={options} />
      </div>
    </header>
  );
};

export function Header() {
  return (
    <StaticQuery
      query={graphql`
        query {
          primaryNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "primary-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
              object_id
              target
              children: wordpress_children {
                title
                url
                classes
                object_id
                slug: object_slug
                target
              }
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              locations {
                locationName
                addressStreet
                addressRegion
                addressPostCode
                addressCity
                email
                phone
              }
            }
          }
        }
      `}
      render={(data) => (
        <GlobalStateProvider>
          <HeaderComponent data={data} />
        </GlobalStateProvider>
      )}
    />
  );
}
