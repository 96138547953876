import { GatsbyLink, NavigationSubItem, useGlobalState } from "components";
import React, { useState, useRef, useCallback, useEffect } from "react";
import { ReactComponent as Arrow } from "images/icons/arrow.svg";
import { useWindowSize } from "hooks";

import "./Navigation.scss";

/** Individual menu item */
export const NavigationItem = ({
  menuItem: { title, url, target, children },
}) => {
  const [subnavOpen, setSubnavOpen] = useState(false);
  const hasChildren = children?.length;
  const navItem = useRef(null);
  const {
    dispatch,
    state: { menuOpen },
  } = useGlobalState();

  const [windowSize, setWindowSize] = useState(useWindowSize());

  // close the menu when clicked
  const handleClick = () => {
    setSubnavOpen((open) => !open);
  };

  const mouseEnter = useCallback(() => {
    if (windowSize.width >= 1080) {
      setSubnavOpen(true);
    }
  }, [windowSize]);

  const mouseLeave = useCallback(() => {
    if (windowSize.width >= 1080) {
      setSubnavOpen(false);
    }
  }, [windowSize]);

  // handles closing the menu if an item is clicked and page is navigated
  const handleItemClick = () => {
    menuOpen && dispatch({ type: "setMenuOpen", data: false });
  };

  useEffect(() => {
    if (navItem.current) {
      const navElement = navItem.current;
      navElement.addEventListener("mouseenter", mouseEnter);
      navElement.addEventListener("mouseleave", mouseLeave);
      navElement.addEventListener("onClick", handleItemClick);
      return () => {
        if (navElement) {
          navElement.removeEventListener("mouseenter", mouseEnter);
          navElement.removeEventListener("mouseleave", mouseLeave);
        }
      };
    }
  }, [mouseEnter, mouseLeave]);

  if (hasChildren && url !== "#") {
    //
    // if the link has children and is a link
    //
    return (
      <li
        className={`navigation-item${hasChildren ? " has-children" : ""}${
          subnavOpen ? " sub-navigation-open" : ""
        }`}
        ref={navItem}
      >
        <GatsbyLink
          to={url || undefined}
          target={target}
          aria-label={title}
          title={title}
          className="sub-navigation-link"
          onClick={() => {
            handleItemClick();
          }}
        >
          {title}
        </GatsbyLink>
        <span
          className="arrow"
          onClick={(e) => {
            handleClick();
          }}
        >
          <Arrow />
        </span>
        <NavigationSubItem
          subMenuItems={children}
          closeMenu={() => handleItemClick()}
        />
      </li>
    );
  } else if (hasChildren && url === "#") {
    //
    // if the link has children but doesn't have a page
    //
    return (
      <li
        className={`navigation-item${hasChildren ? " has-children" : ""}${
          subnavOpen ? " sub-navigation-open" : ""
        }`}
        ref={navItem}
      >
        <span
          className="sub-navigation-link"
          onClick={(e) => {
            handleClick();
          }}
        >
          {title}
        </span>
        <span
          className="arrow"
          onClick={(e) => {
            e.preventDefault();
            setSubnavOpen((open) => !open);
          }}
        >
          <Arrow />
        </span>
        <NavigationSubItem
          subMenuItems={children}
          closeMenu={() => handleItemClick()}
        />
      </li>
    );
  } else {
    //
    // no children
    //
    return (
      <li
        className="navigation-item"
        onClick={() => {
          handleItemClick();
        }}
      >
        <GatsbyLink
          to={url || undefined}
          target={target}
          aria-label={title}
          title={title}
          className="sub-navigation-link"
        >
          {title}
        </GatsbyLink>
        <span className="arrow">
          <GatsbyLink
            to={url || undefined}
            target={target}
            aria-label={title}
            title={title}
          >
            <Arrow />
          </GatsbyLink>
        </span>
      </li>
    );
  }
};
