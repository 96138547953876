import React, { useEffect, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyLink, SocialLinks } from "components";
import { ReactComponent as Logo } from "images/logos/logo.svg";
import { ReactComponent as Arrow } from "images/icons/arrow.svg";
import { ReactComponent as LineDetail } from "images/footer-line-detail.svg";
import { decodeEntities } from "../../utils/helpers";
import "./Footer.scss";

const FooterComponent = ({
  data: {
    siteSettings: { options },
    footerNavigation: { items: footerNav },
    legalNavigation: { items: legalNav },
    wordpressWpSettings,
  },
  location,
}) => {
  const currentYear = new Date().getFullYear();
  const [isHomePage, setHomePage] = useState(false);

  useEffect(() => {
    setHomePage(false);
    if (location.pathname === "/") {
      setHomePage(true);
    }
  }, [location.pathname]);

  return (
    <footer className={`footer layout ${!isHomePage ? "overflow" : ""}`}>
      <div className="footer-detail">
        <LineDetail />
      </div>
      <div className="footer-container">
        <div className="footer-top">
          <div>
            <GatsbyLink to="/" className="logo-container">
              <Logo />
            </GatsbyLink>
            <div className="contact-details">
              {options?.locations.map((location, index) => (
                <React.Fragment key={index}>
                  <address className="contact-details__item">
                    {location?.addressStreet}
                    <br />
                    {location?.addressCity} {location?.addressRegion}{" "}
                    {location?.addressPostCode}
                  </address>
                  <a
                    className="contact-details__item"
                    href={`tel:${location?.phone}`}
                  >
                    {location?.phone}
                  </a>
                  <a
                    className="contact-details__item"
                    href={`mailto:${decodeEntities(location?.email)}`}
                  >
                    {decodeEntities(location?.email)}
                  </a>
                </React.Fragment>
              ))}
            </div>

            <SocialLinks />
          </div>
          <div>
            <ul className="footer-nav">
              {footerNav &&
                footerNav.map((item, index) => (
                  <li key={index} className="footer-item__item">
                    <GatsbyLink
                      to={item.url}
                      aria-label={item.title}
                      decode={true}
                    >
                      {item.title}
                    </GatsbyLink>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          {legalNav && (
            <ul className="legal-nav">
              <li className="legal-nav__item">
                ©{currentYear} {wordpressWpSettings.title}
              </li>
              {legalNav.map((item, index) => (
                <li key={index} className="legal-nav__item">
                  <GatsbyLink to={item.url} decode={true}>
                    {item.title}
                  </GatsbyLink>
                </li>
              ))}
            </ul>
          )}
          {isHomePage ? (
            <span className="distl">
              Website by{" "}
              <a rel="noreferrer" target="_blank" href="https://distl.com.au/">
                Distl
              </a>
            </span>
          ) : (
            <span className="distl">Website by Distl</span>
          )}
        </div>
      </div>
    </footer>
  );
};

export function Footer(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          legalNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "legal-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
            }
          }
          footerNavigation: wordpressWpApiMenusMenusItems(
            slug: { eq: "footer-navigation" }
          ) {
            items {
              title
              url
              classes
              slug: object_slug
              children: wordpress_children {
                title
                url
                classes
                slug: object_slug
              }
            }
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              locations {
                locationName
                addressStreet
                addressRegion
                addressPostCode
                addressCity
                email
                phone
              }
            }
          }
          wordpressWpSettings {
            title
          }
        }
      `}
      render={(data) => <FooterComponent data={data} {...props} />}
    />
  );
}

// export function Footer() {
//     return (
//   <StaticQuery
//     query={graphql`
//       query {
//         legalNavigation: wordpressWpApiMenusMenusItems(
//           slug: { eq: "legal-navigation" }
//         ) {
//           items {
//             title
//             url
//             classes
//             slug: object_slug
//           }
//         }
//         footerNavigation: wordpressWpApiMenusMenusItems(
//           slug: { eq: "footer-navigation" }
//         ) {
//           items {
//             title
//             url
//             classes
//             slug: object_slug
//             children: wordpress_children {
//               title
//               url
//               classes
//               slug: object_slug
//             }
//           }
//         }
//         siteSettings: wordpressAcfOptions(options: {}) {
//           options {
//             locations {
//               locationName
//               addressStreet
//               addressRegion
//               addressPostCode
//               addressCity
//               email
//               phone
//             }
//           }
//         }
//         wordpressWpSettings {
//           title
//         }
//       }
//     `}
//     render={(data) => <FooterComponent data={data} {...props} />}
//   />
// );
