import React from "react";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import { ReactComponent as Overlay1 } from "images/hero-line-detail-1.svg";
import { ReactComponent as Overlay2 } from "images/hero-line-detail-2.svg";
import { ReactComponent as Overlay3 } from "images/hero-line-detail-3.svg";
import heroAnimation from "./lottie-animation.json";
import "./Hero.scss";
import { Player } from "@lottiefiles/react-lottie-player";

export const Hero = ({
  heading,
  eyebrow,
  content,
  animation,
  overlayImage,
  image,
  isCentered,
}) => {
  const overlays = Object.freeze({
    overlay1: <Overlay1 />,
    overlay2: <Overlay2 />,
    overlay3: <Overlay3 />,
  });
  return (
    <section
      className={`hero layout ${animation ? "animated" : ""} ${
        overlayImage ? "graphic-overlay" : ""
      }${isCentered ? "centered" : ""}`}
    >
      <div className="hero-container">
        <div>
          <span
            className="eyebrow"
            dangerouslySetInnerHTML={{ __html: eyebrow }}
          />
          <h1
            className="heading"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          {content && <RenderContent content={content} />}
        </div>
        {animation && (
          <div className="animation">
            <Player
              autoplay
              loop
              src={heroAnimation}
              style={{ height: "600px", width: "600px" }}
            ></Player>
          </div>
        )}
      </div>
      {image && (
        <>
          <div className="hero-image">
            <ImagePass src={image} />
          </div>
          {overlayImage && (
            <div className={`overlay ${overlayImage}`}>
              {overlays[overlayImage]}
            </div>
          )}
        </>
      )}
    </section>
  );
};

Hero.defaultProps = {
  heading: "Default Title",
  eyebrow: "Default SubTitle",
  animation: false,
};
