// https://codesandbox.io/s/bitter-night-i5mhj?file=/src/index.tsx
// Kindly taken from the above source and Pilbara Solar project

import * as React from "react";

const globalStateContext = React.createContext(undefined);

function globalStateReducer(state, action) {
  switch (action.type) {
    case "setMenuOpen": {
      if (state.menuOpen !== action.data) {
        if (typeof document !== "undefined" && "querySelector" in document) {
          if (action.data === true) {
            document.querySelector("body").classList.add("nav-open");
          } else if (action.data === false) {
            document.querySelector("body").classList.remove("nav-open");
          }
        }
        return { ...state, menuOpen: action.data };
      }
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function GlobalStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(globalStateReducer, {
    menuOpen: false,
  });
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch };
  return (
    <globalStateContext.Provider value={value}>
      {children}
    </globalStateContext.Provider>
  );
}

function useGlobalState() {
  const context = React.useContext(globalStateContext);
  if (context === undefined) {
    throw new Error();
  }
  return context;
}

export { GlobalStateProvider, useGlobalState };
