import React, { useEffect, useCallback, useRef } from "react";
import { GatsbyLink, useGlobalState } from "components";

import logoAnimation from "./animations/logo-animation.json";
import { Player } from "@lottiefiles/react-lottie-player";

export const AnimatedLogo = () => {
  // handles closing the menu if logo is clicked and page is navigated
  const {
    dispatch,
    state: { menuOpen },
  } = useGlobalState();

  const handleItemClick = () => {
    menuOpen && dispatch({ type: "setMenuOpen", data: false });
  };

  // handles the hover functionality and running the Lottie animationEl
  const lottieRef = useRef(null);
  const el = useRef(null);

  const mouseEnter = useCallback(() => {
    lottieRef.current.play();
  }, []);

  useEffect(() => {
    if (el.current) {
      const animationEl = el.current;
      animationEl.addEventListener("mouseenter", mouseEnter);
      return () => {
        if (animationEl) {
          animationEl.removeEventListener("mouseenter", mouseEnter);
        }
      };
    }
  }, [mouseEnter]);

  return (
    <GatsbyLink
      to="/"
      className="logo-container"
      onClick={() => handleItemClick()}
    >
      {/* empty div for the ref */}
      <div ref={el}>
        <Player
          src={logoAnimation}
          style={{ height: "40px", width: "192px" }}
          ref={lottieRef}
        ></Player>
      </div>
    </GatsbyLink>
  );
};
