import React from "react";
import { useGlobalState } from "components";
import "./Hamburger.scss";

export const Hamburger = () => {
  const {
    dispatch,
    state: { menuOpen },
  } = useGlobalState();

  return (
    <button
      type="button"
      className={`hamburger${menuOpen ? " isActive" : ""}`}
      onClick={() => dispatch({ type: "setMenuOpen", data: !menuOpen })}
      aria-label="Toggle Menu"
    >
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </button>
  );
};
