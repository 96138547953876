import React from "react";
import { Link } from "gatsby";
import { decodeEntities, trailingSlash, isBrowser } from "../../utils/helpers";

export const scrollIntoView = (event, containerId) => {
  if (typeof window !== "undefined") {
    event.preventDefault();
    containerId = containerId.replace("#", "");
    const results = document.getElementById(containerId)
      ? document.getElementById(containerId)
      : null;
    results && results.scrollIntoView({ block: "start", behavior: "smooth" });
  }
};

export const GatsbyLink = (props) => {
  const {
    to,
    children,
    data,
    decode = false,
    className = null,
    ...other
  } = props;

  const internal = /^\/(?!\/)/.test(to);

  const isActive = () => {
    const currentLocation = isBrowser && window.location;
    if (to.includes(currentLocation)) {
      return className + " active";
    }

    return className;
  };

  if (to && to.includes("#")) {
    return (
      <span
        onClick={(event) => this.scrollIntoView(event, to)}
        className={className}
        {...other}
      >
        {decode ? decodeEntities(children) : children}
      </span>
    );
  }

  if (internal) {
    return (
      <Link
        data-internal="true"
        className={isActive()}
        {...other}
        to={trailingSlash(to)}
      >
        {decode ? decodeEntities(children) : children}
      </Link>
    );
  }

  return (
    <a href={to} data-internal="false" {...other} className={className}>
      {decode ? decodeEntities(children) : children}
    </a>
  );
};
