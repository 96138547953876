import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./SocialLinks.scss";
import { icons } from "./icons";

export const PassSocialLinks = ({
  data: {
    siteSettings: {
      options: { socialLinks },
    },
  },
}) => {
  return (
    <div className="social-links">
      {socialLinks &&
        socialLinks.map((item, index) => (
          <a
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            key={index}
          >
            {icons[item.type]}
          </a>
        ))}
    </div>
  );
};

export const SocialLinks = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              socialLinks {
                type
                url
              }
            }
          }
        }
      `}
      render={(data) => <PassSocialLinks {...props} data={data} />}
    />
  );
};
